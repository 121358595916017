import {JsonObject, JsonProperty} from "json2typescript";
import Video from "@/models/Video";
import Document from "@/models/Document";

@JsonObject("Collection")
export default class Collection {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("video", Video, true)
    video?: Video = undefined
    @JsonProperty("videos", [Video], true)
    videos?: Video[] = undefined
    @JsonProperty("images", [Document], true)
    images?: Document[] = undefined
    @JsonProperty("image", Document, true)
    image?: Document = undefined

}
