




















































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Collection from "@/models/Collection";
import CollectionService from "@/services/CollectionService";

@Component
export default class CollectionsView extends Vue {
    @Ref() readonly form!: HTMLFormElement
    collections: Collection[] = []
    page: number = 1
    pageCount: number = 0
    loading: boolean = false
    search: string = ""
    collection: Collection = new Collection()
    headers = [
        { text: "Id", value: "id", width: "120px" },
        { text: "Nombre", value: "name", width: "200px" },
        { text: "Url", value: "url", width: "300px" },
        { text: "Visualizaciones", value: "displays", width: "150px" },
    ]
    dialog: boolean = false
    // urlRules = [
    //     (v: string) => v && v.length > 0 ? true : "Url requerida",
    //     (v: string) => StringTool.validateUrl(v) ? true : "Url no es válida"
    // ]

    created() {
        CollectionService.getCollections(this, this.collections)
    }

    rowClick(collection: Collection) {
        this.$router.push({path: "/collections/" + collection.id})
    }

    openUrl(url: string) {
        window.open(url, "_blank");
    }

    openCreateDialog() {
        if (this.form) this.form.resetValidation()
        this.dialog = true
        this.collection = new Collection()
    }

    createCollection() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                "Aviso",
                "¿Está seguro de crear la colección?",
                () => CollectionService.postCollection(this, this.collection)
            ))
        }
    }
}
