import Collection from "@/models/Collection";
import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import Video from "@/models/Video";

export default class CollectionService {


    static postCollection(component: Vue, collection: Collection) {
        let formData: FormData = new FormData()
        formData.set("name", collection.name!)
        // @ts-ignore
        component.loading = true
        component.axios.post(ConstantTool.BASE_URL + "/api/collections",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                let item = JsonTool.jsonConvert.deserializeObject(response.data, Video)
                component.$router.push({path: "/collections/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear la colección"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static postCollectionImage(component: Vue, collectionId: number, image: File) {
        let formData: FormData = new FormData()
        formData.set("image", image)
        // @ts-ignore
        component.loading = true
        component.axios.post(ConstantTool.BASE_URL + "/api/collections/" + collectionId + "/images",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo añadir la imagen"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static deleteCollectionImage(component: Vue, collectionId: number, imageId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.delete(ConstantTool.BASE_URL + "/api/collections/" + collectionId + "/images/" + imageId, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo eliminar la imagen"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchCollection(component: Vue, collectionId: number, collection: Collection, image: File | null = null) {
        let formData: FormData = new FormData()
        formData.set("name", collection.name!)
        if (collection.video) formData.set("videoId", collection.video!.id!.toString())
        if (image) formData.set("image", image)
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/collections/" + collectionId,
            formData, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar la colección"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getCollections(component: Vue, collections: Collection[]) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/collections", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, Collection)
                collections.splice(0, collections.length)
                list.forEach(v => collections.push(v))
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener las colecciones"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getCollection(component: Vue, collectionId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/collections/" + collectionId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.collection = JsonTool.jsonConvert.deserializeObject(response.data, Collection)
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener la colección"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchCollectionVideoRelate(component: Vue, collectionId: number, videoId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/collections/" + collectionId + "/videos/" + videoId + "/relate",
            null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo añadir el video"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchCollectionVideoUnrelate(component: Vue, collectionId: number, videoId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/collections/" + collectionId + "/videos/" + videoId + "/unrelate",
            null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo eliminar el video"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

}
